import { Link } from "react-scroll";
import styled, { css } from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const Nav = styled.nav`
	background-color: var(--primary);
	height: 100vh;
	width: 120vw;
	font-size: 1.1rem;
	padding: 15.3rem 0 0 2.8rem;
	color: var(--background);
	position: fixed;
	z-index: 99999999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: left 500ms ease;
	${(props) =>
		props.isClosed &&
		css`
			left: 100vw;
		`}
`;

const NavItem = styled.li`
	padding-bottom: 1.2rem;
`;
const StyledLink = styled(RouterLink)`
	text-decoration: none;
	color: white;
	font-size: 1.2rem;
	&:focus,
	&:hover,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
	}
`;

const BracketNav = ({ onClose, isOpen }) => {
	const NavLink = ({ ...props }) => (
		<Link
			activeClass="nav-link_active"
			onClick={onClose}
			containerId="bracket-main"
			{...props}
		>
			{props.children}
		</Link>
	);

	return (
		<>
			<Nav isClosed={!isOpen}>
				<ul>
					<NavItem>
						<NavLink to="intro">INTRO</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="howtoplay">HOW TO PLAY</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="features">FETURES</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="faq">FAQ</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="contactUs">CONTACTS</NavLink>
					</NavItem>
					<NavItem>
						<StyledLink onClick={() => onClose()} to="/">
							{" "}
							Home
						</StyledLink>
					</NavItem>
				</ul>
			</Nav>
		</>
	);
};

export default BracketNav;
