import styled from "styled-components";
import { ViewContainer } from "../../../components/general-components";


export const IntroWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Container = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	@media all and (min-width: 768px) {
		flex-direction: row;
	}
`;

export const IntroContent = styled.div`
	padding: 30px 10px;
	background-color: var(--background);
	@media all and (min-width: 768px) {
		display: flex;
		padding: 30px 0;
		align-items: flex-start;
	}
`;

export const ContentTitle = styled.h3`
	padding: unset;
	margin: unset;
	margin-bottom: 0.8rem;
	color: #09274b;
	font-size: 22px;
	font-weight: 800;
	@media all and (min-width: 768px) {
		font-size: 18px;
	}
`;

export const ContentPara = styled.p`
	text-align: left;
	padding: unset;
	margin: unset;
	color: #09274b;
	font-size: medium;
	max-width: 450px;
	font-family: Sen;
	@media all and (min-width: 768px) {
		font-size: 1rem;
		line-height: 1.2rem;
	}
`;

export const H1 = styled.h1`
	font-weight: 800;
	color: var(--background);
	font-size: 1.2rem;
	margin: 0;
	margin-bottom: 0.6rem;
	white-space: nowrap;
	text-shadow: 4px 6px 11px #00000066;
	@media (min-width: 769px) {
		font-size: 2.9rem;
		color: var(--background);
	}
`;

export const H2 = styled.h2`
	font-weight: 800;
	font-size: 1.8rem;
	margin: 0;
	color: #54ffaa;
	text-shadow: 4px 6px 11px #00000066;
	@media (min-width: 48.0625rem) {
		font-size: 3.5rem;
		color: #54ffaa;
	}
`;

export const Image = styled.img`
	width: 21.875rem;
	height: 25rem;
`;


