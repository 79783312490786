import * as S from "./Intro.styles";
import { IntroContentPhone, IntroPhone } from "../../../assets/images/bracket";
import { useMediaQuery } from "react-responsive";

export const Intro = ({ bgImage }) => {
	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

	return (
		<S.IntroWrapper>
			<S.Container
				isMobile={isMobile}
				style={{ backgroundImage: `url(${bgImage})` }}
			>
				{!isMobile && (
					<img
						src={IntroPhone}
						alt="intro-phone"
						style={{ maxWidth: "400px" }}
					/>
				)}
				<div
					style={{
						marginBottom: "30px",
						display: "flex",
						flexDirection: isMobile ? "column-reverse" : "column",
					}}
				>
					<S.H1>QATAR 2022</S.H1>
					<S.H2>
						TOURNAMENT <br /> BRACKET PREDICTOR
					</S.H2>
				</div>
				{isMobile && (
					<img
						src={IntroPhone}
						alt="intro-phone"
						style={{ maxWidth: "300px", maxHeight: "350px" }}
					/>
				)}
			</S.Container>
			<S.IntroContent>
				<div style={{ flex: 0.3 }}></div>
				<div
					style={{
						flex: 0.5,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						gap: "40px",
					}}
				>
					<div>
						<S.ContentTitle>ATRACT, RETAIN AND REWARD</S.ContentTitle>
						<S.ContentPara>
							Our Free-to-Play world cup 2022 tournament predictor is the
							perfect opportunity to acquire new players, retain & reword loyal
							punters, and seek to re-engage dormant accounts.
						</S.ContentPara>
					</div>
					<div>
						<S.ContentTitle>
							WHITE LABEL - <br /> YOUR GAME YOUR WAY
						</S.ContentTitle>
						<S.ContentPara>
							We created the game as white label solution so you can design it
							according to your brand guidelines.
							<p>
								Our team will work closely with you to deliver a tournament
								bracket that is tailored to your brand and needs.
							</p>
						</S.ContentPara>
					</div>
				</div>
				<div style={{ flex: 0.5 }}>
					{!isMobile && (
						<img
							src={IntroContentPhone}
							alt=""
							width={"250px"}
							height={"300px"}
						/>
					)}
				</div>
			</S.IntroContent>
		</S.IntroWrapper>
	);
};

export default Intro;
