import styled from "styled-components";
import About from "./about";
import Contacts from "./contacts";
import Home from "./home";
import Products from "./products";

const Main = styled.main`
	height: calc((100 * var(--vh)) - var(--header-height));
	overflow: auto;
	scroll-snap-type: y mandatory;
`;

const Footer = styled.footer`
	font-family: sans-serif;
	font-size: 0.6rem;
	color: #9d9d9d;
	text-align: center;
	padding: 1rem 0;
	line-height: 0.7rem;
	font-weight: 100;
	height: var(--footer-height);
	background-color: var(--background);
`;

const MainSite = () => {
	return (
		<Main id="main">
			<section className={"snappable"} id={"home"}>
				<Home />
			</section>
			<section className={"snappable"} id={"about"}>
				<About />
			</section>
			<section id={"products"}>
				<Products />
			</section>
			<section className={"snappable"} id={"contacts"}>
				<Contacts />
			</section>
			<Footer>
				<img src="/images/footerLogo.svg" alt="brand_logo" />
				<p>
					Copyright © {new Date().getFullYear()} HookApps All rights reserved
				</p>
			</Footer>
		</Main>
	);
};

export default MainSite;
