import styled from 'styled-components';
import { NextPageLink, ViewContainer, shortScreenQuery, desktopScreenQuery } from '../components/general-components';

const H3 = styled.h3`
    margin: 0;
    font-weight: 700;
    color: var(--background);
    font-size: 2.2rem;
    padding-bottom: 1rem;
    ${shortScreenQuery} {
        font-size:1.5rem;
    };
    @media (min-width: 769px) {
        text-align: center;
        font-size: 3rem;
        padding-bottom: 1rem;
    };
`

const Container = styled(ViewContainer)`
    --black:#4040408a;
    background-image: linear-gradient(0deg, var(--black), var(--black)), url(/images/aboutBG.png);
    padding: 1rem 1.4rem 0 1.4rem;
    @media (min-width: 769px) {
        padding: 1rem 1.4rem 0 1.4rem;
    }
`
const BoxesContainers = styled.div`
    margin:0 auto;
    max-width: 95%;
    width: 30rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
`

const BoxContainer = styled.div`
    padding: .3rem;
    display:block;
    width: calc(50% - 1px);
    min-width: 4rem;
    @media (min-width: 769px) {
        padding: 1rem;
    };
`

const InnerBox = styled.div`
    backdrop-filter: blur(3px);
    background-color: #ffffff69;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px #00000040;
    padding: 1rem 1rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size:.7rem;
    line-height: 1rem;
    ${desktopScreenQuery} {
        font-size: .8rem;
        line-height: 1.2rem;
    }
`

const Box = ({ imgUrl, children, alt }) => (
    <BoxContainer>
        <InnerBox>
            <img src={imgUrl} style={{ maxHeight: "8vh" }} alt={alt} />
            <p>
                {children}
            </p>
        </InnerBox>
    </BoxContainer>
)

const About = () => {
    return (
        <Container>
            <H3>
                We create games for sport fans
            </H3>
            <BoxesContainers>
                <Box imgUrl={"/images/about/goalkeeper.png"} alt={"field_image"}>
                    We specialize in the <b>betting & gaming industry.</b>
                </Box>
                <Box imgUrl={"/images/about/field.png"} alt={"field_image"}>
                    We offer <b>free-to-play</b> and <b>pay-to-play</b> games & applications</Box>
                <Box imgUrl={"/images/about/strategy.png"} alt={"field_image"}>
                    We focus on customer engagement, user interface, and <b>data driven strategy.</b></Box>
                <Box imgUrl={"/images/about/soccer-player.png"} alt={"field_image"}>
                    We help clients <b>acquire</b> new users, <b>retain</b> existing customers and <b>reward</b> loyal players.
                </Box>
            </BoxesContainers>
            <NextPageLink to="products" containerId="main">
                Our products
            </NextPageLink>
        </Container>
    )
}

export default About;