import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Contact from "./Contact/Contact";
import Faq from "./Faq";
import Features from "./Features/Features";
import HowToPlay from "./HowToPlay/HowToPlay";
import { IntroBanner } from "../../assets/images/bracket";
import useProgressiveImage from "../../hooks/useProgressiveImage";
import Intro from "./Intro";
import CircularProgress from "@mui/material/CircularProgress";

const Main = styled.main`
	height: calc((100 * var(--vh)) - var(--header-height));
	overflow-x: hidden;
`;

const Footer = styled.footer`
	font-family: sans-serif;
	font-size: 0.6rem;
	color: #9d9d9d;
	text-align: center;
	padding: 1rem 0;
	line-height: 0.7rem;
	font-weight: 100;
	height: var(--footer-height);
	background-color: var(--background);
`;

const LoaderBox = styled.div`
	text-align: center;
	padding: 50px;
`;

const Bracket = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const loaded = useProgressiveImage(IntroBanner);

	return !loaded ? (
		<LoaderBox>
			<CircularProgress />
		</LoaderBox>
	) : (
		<>
			<Main id="bracket-main">
				<section id={"intro"}>
					<Intro bgImage={loaded} />
				</section>
				<section id={"howtoplay"}>
					<HowToPlay />
				</section>
				<section id={"features"}>
					<Features />
				</section>
				<section style={{ margin: isMobile ? "" : "2rem 0" }} id={"faq"}>
					<Faq />
				</section>
				<section id={"contactUs"}>
					<Contact />
				</section>
				<Footer>
					<img src="/images/footerLogo.svg" alt="brand_logo" />
					<p>
						Copyright © {new Date().getFullYear()} HookApps All rights reserved
					</p>
				</Footer>
			</Main>
		</>
	);
};

export default Bracket;
