import * as S from "./HowToPlay.styles";
import {
	HowToPlayImg1,
	HowToPlayImg2,
	HowToPlayImg3,
	HowToPlayImg4,
} from "../../../assets/images/bracket";
import { useMediaQuery } from "react-responsive";
import { Swiper } from "swiper/react";
import { Pagination } from "swiper";

// CSS imports for slider
import "../../../../node_modules/swiper/swiper-bundle.min.css";
import "./slider.css";

const MobileHowToPlay = () => {
	return (
		<S.HowToPlayWrapper>
			<S.HowToPlayTitle>How To Play</S.HowToPlayTitle>
			<Swiper
				modules={[Pagination]}
				spaceBetween={300}
				slidesPerView={1}
				pagination={true}
			>
				<S.SliderItem>
					<S.HowToPlaySliderImg src={HowToPlayImg1} alt="" />
					<S.SliderContainer>
						<S.HowToPlayContentTitle>
							GROUPS STAGE PREDICTIONS
						</S.HowToPlayContentTitle>
						<S.HowToPlayContentPara>
							Players are asked to predict each group’s final standings (1st,
							2nd, 3rd, and 4th ).
						</S.HowToPlayContentPara>
					</S.SliderContainer>
				</S.SliderItem>
				<S.SliderItem>
					<img src={HowToPlayImg2} style={{ width: "90%" }} alt="" />
					<S.SliderContainer>
						<S.HowToPlayContentTitle>
							KNOCKOUT STAGE PREDICTIONS
						</S.HowToPlayContentTitle>
						<S.HowToPlayContentPara>
							Players are asked to predict the winner of each knockout match all
							the way to the final and crown the champions.
						</S.HowToPlayContentPara>
					</S.SliderContainer>
				</S.SliderItem>
				<S.SliderItem>
					<img src={HowToPlayImg3} alt="" width={200} />
					<S.SliderContainer>
						<S.HowToPlayContentTitle>
							SHARING PREDICTIONS
						</S.HowToPlayContentTitle>
						<S.HowToPlayContentPara>
							Make it easy for your players to show their skills and bring their
							friends to compete.
						</S.HowToPlayContentPara>
					</S.SliderContainer>
				</S.SliderItem>
				<S.SliderItem>
					<img
						width={250}
						style={{ marginLeft: "4rem" }}
						src={HowToPlayImg4}
						alt=""
					/>
					<S.SliderContainer>
						<S.HowToPlayContentTitle>
							FOLLOW THE PROGRESS AND WIN PRIZES{" "}
						</S.HowToPlayContentTitle>
						<S.HowToPlayContentPara>
							Players collect points for making correct predictions, climbing
							the leaderboard and competing to win prizes.
						</S.HowToPlayContentPara>
					</S.SliderContainer>
				</S.SliderItem>
			</Swiper>
		</S.HowToPlayWrapper>
	);
};
const DesktopHowToPlay = () => {
	return (
		<S.HowToPlayWrapper>
			<S.HowToPlayTitle>How To Play</S.HowToPlayTitle>
			<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
				<S.HowToPlayContainer>
					<S.HowToPlayItem>
						<S.SubSection>
							<img src={HowToPlayImg1} alt="" />
							<S.HowToPlayContentTitle>
								GROUPS STAGE PREDICTIONS
							</S.HowToPlayContentTitle>
							<S.HowToPlayContentPara>
								Players are asked to predict each group’s final standings (1st,
								2nd, 3rd, and 4th ).
							</S.HowToPlayContentPara>
						</S.SubSection>
						<S.SubSection>
							<img
								width={150}
								height={200}
								src={HowToPlayImg3}
								alt=""
								style={{ marginRight: "60%" }}
							/>
							<S.HowToPlayContentTitle>
								SHARING PREDICTIONS
							</S.HowToPlayContentTitle>
							<S.HowToPlayContentPara>
								Make it easy for your players to show their skills and bring
								their friends to compete.
							</S.HowToPlayContentPara>
						</S.SubSection>
					</S.HowToPlayItem>
					<S.HowToPlayItem>
						<S.SubSection>
							<img src={HowToPlayImg2} alt="" />
							<S.HowToPlayContentTitle>
								KNOCKOUT STAGE PREDICTIONS
							</S.HowToPlayContentTitle>
							<S.HowToPlayContentPara>
								Players are asked to predict the winner of each knockout match
								all the way to the final and crown the champions.
							</S.HowToPlayContentPara>
						</S.SubSection>
						<S.SubSection>
							<img
								height={200}
								style={{ margin: "0 auto", marginBottom: "5px" }}
								src={HowToPlayImg4}
								alt=""
							/>
							<S.HowToPlayContentTitle>
								FOLLOW THE PROGRESS AND WIN PRIZES{" "}
							</S.HowToPlayContentTitle>
							<S.HowToPlayContentPara>
								Players collect points for making correct predictions, climbing
								the leaderboard and competing to win prizes.
							</S.HowToPlayContentPara>
						</S.SubSection>
					</S.HowToPlayItem>
				</S.HowToPlayContainer>
			</div>
		</S.HowToPlayWrapper>
	);
};

const HowToPlay = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	return isMobile ? <MobileHowToPlay /> : <DesktopHowToPlay />;
};
export default HowToPlay;
