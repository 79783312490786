import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import smoothscroll from "smoothscroll-polyfill";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";

ReactGA.initialize("UA-204804134-4", {
	useExistingGa: true,
	debug: true,
	// titleCase: false,
	// gaOptions: {
	//   userId: 123,
	// },
	// cookieFlags: "SameSite=None; Secure",
});
ReactGA.ga("create", "UA-204804134-4", "auto");

ReactGA.pageview(window.location.pathname + window.location.search);

// polyfill for scroll-behaviour
smoothscroll.polyfill();

// Fix the problem that the bottom panel is not counted in viewport height
const setVhHeight = () => {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
};
setVhHeight();
window.addEventListener("resize", setVhHeight);

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<App />
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
