import styled from 'styled-components';
import { NextPageLink, ViewContainer } from '../components/general-components';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';
import { Events } from 'react-scroll';

const H1 = styled.h1`
    font-weight: 600;
    color: var(--background);
    font-size: 3.7rem;
    margin: 0;
    margin-bottom: .6rem;
    @media (min-width: 769px) {
        color: var(--primary);
    }
`

const H2 = styled.h2`
    font-weight: 500;
    font-size: 1.4rem;
    margin: 0;
    color: var(--primary);
    @media (min-width: 769px) {
        color: var(--background);
    }
`

const Container = styled(ViewContainer)`
    text-align: center;
    padding: 6.2rem 0;
    --black:#0000009a;
    background-image: linear-gradient(0deg, var(--black), var(--black)), url(/images/stadium-bg.png);
`

const Home = () => {
	// useEffect(() => {
	//     console.log("here")
	//     document.querySelector("#main").addEventListener("scroll", function () {
	//         console.log(34)
	//     }, false);
	// }, [])

	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

	return (
		<Container>
			{!isMobile && <img src="/images/logo-big.svg" alt="brand_logo" />}
			<H1>Hook Apps</H1>
			<H2>We've got games</H2>
			<NextPageLink to="about" containerId="main">
				About us
			</NextPageLink>
		</Container>
	);
}

export default Home