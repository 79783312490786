import styled from "styled-components";
import { ViewContainer } from "../../../components/general-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 3rem 15%;
	background-size: cover;
	background-position: 0px 100px;
	background-repeat: no-repeat;
	@media all and (min-width: 768px) {
		margin-top: 3rem;
		margin-bottom: 3rem;
		background-position: center;
		justify-content: flex-start;
		background-size: 70%;
	}
`;
export const Wrapper = styled(ViewContainer)`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
	align-items: center;
	@media all and (min-width: 768px) {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-around;
		width: 100%;
	}
`;

export const Title = styled.h3`
	text-align: center;
	font-weight: 800;
	color: #09274b;
	font-size: 2.5rem;
	letter-spacing: 2px;
	@media all and (min-width: 768px) {
		text-align: left;
		font-size: 2rem;
	}
`;

export const ContentItem = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	@media all and (min-width: 768px) {
		text-align: left;
	}
`;

export const ContentTitle = styled.h3`
	font-weight: 800;
	font-size: 1.3rem;
`;

export const ContentText = styled.p`
	margin: unset;
	font-size: 1rem;
`;
export const BannerContainer = styled.div`
	background-color: #09274b;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 3.12rem 31px;
	@media all and (min-width: 768px) {
		display: flex;
		width: 100%;
		align-items: flex-end;
	}
`;

export const BannerContent = styled.div`
	width: 84%;
	display: flex;
	position: relative;
	align-items: center;
`;

export const BannerTitle = styled.h2`
	color: #fff;
	font-size: 1.3em;
	text-align: left;
	@media all and (min-width: 768px) {
		font-weight: 800;
		font-size: 1.5em;
		width: 100%;
	}
`;
export const BannerSubTitle = styled.div`
	color: #fff;
	font-size: 1.5em;
	margin-bottom: 3.12rem;
	@media all and (min-width: 768px) {
		font-size: 1.2rem;
	}
`;

export const FeatureItem = styled.div`
	display: flex;
	align-items: flex-start;
	margin: 10px 0;
	@media all and (min-width: 768px) {
		width: 80%;
		display: "flex";
		gap: "15px";
		align-items: "center";
		margin: 20px 0;
	}
`;

export const FeatureItemIndex = styled.div`
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: #54ffaa;
	color: #09274b;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.3em;
	margin-right: 10px;
	@media all and (min-width: 768px) {
		width: 25px;
		height: 25px;
	}
`;

export const FeatureItemText = styled.div`
	color: #fff;
	flex: 1;
	font-size: 1rem;
	@media all and (min-width: 768px) {
		font-size: 1rem;
	}
`;

export const FeaturesContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
export const FeaturesContentList = styled.div`
	width: 100%;
`;

export const ImageBox = styled.div`
display: flex;
`;
