import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import { desktopScreenQuery, shortScreenQuery } from '../components/general-components';

const Container = styled.div`
    background: ${props => props.primary ? `var(--primary)` : `var(--background)`};
    padding: 1.6rem 1.4rem 0 1.4rem;
    border-radius: 7px;
    ${shortScreenQuery} {
        padding: 1rem 1.4rem 0 1.4rem;
    }
    color: var(--tertiary);
`

const InnerContainer = styled.div`
    max-width: 37.5rem;
    margin: 0 auto;
`

const ProductImg = styled.img`
    width: 100%;
    margin-left: -2%;
    @media (min-width: 769px) {
        width: 35rem;
        margin: 0 auto;
    }
    ${shortScreenQuery} {
        width: 100%;
        margin-left: 0%;
    }
`

const BigProductImg = styled.img`
    max-height: 26rem;
    max-width: 100%;
    margin: 0 auto;
    display: block;
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 0rem;
    ${shortScreenQuery} {
        padding: 1rem 0rem;
    }
    ${desktopScreenQuery} {
        padding: 0rem 0 0 0;
        font-size: 3rem;
        padding: 1.2rem 0rem;
    }
`

const Btn = styled.button`
    border: none;
    background-color: ${props => props.primary ? `var(--primary)` : `var(--background)`};
    color: ${props => !props.primary ? `var(--primary)` : `var(--background)`};
`

const BtnLeft = styled.button`
    border: none;
    color: white;
    background-color: var(--tertiary);
    width: 7.5rem;
    height: 2.9rem;
    border-radius: 7px;
`

const BtnRight = styled(Btn)`
    background: transparent;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 6.5rem;
`

const H3 = styled.h3`
    font-size: 1.9rem;
    font-family: Aharoni;
    margin: 0;
    padding: .5rem 0 0 0;
    ${shortScreenQuery} {
        padding: .5rem 0 0 0;
    }
    ${desktopScreenQuery} {
        padding: 0rem 0 0 0;
        font-size: 3rem;
    }
`

const DesktopFooterImage = styled.img`
    width: 12rem;
`

const Product = ({ headline, description, imageURL, desktopImageURL, desktopFooterURL, alt, primary, paddingLeft }) => {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <Container className={"snappable"} primary={primary}>
            <InnerContainer>
                <H3>
                    {headline}
                </H3>
                <p>
                    {description}
                </p>
                {isMobile || !desktopImageURL
                    ? <ProductImg style={{ paddingLeft }} src={imageURL} alt={alt} />
                    : <BigProductImg src={desktopImageURL} alt={alt} />}
                <ButtonsContainer>
                    <BtnLeft primary={!primary}>
                        Request Demo
                    </BtnLeft>
                    {!isMobile && <DesktopFooterImage src={desktopFooterURL} alt="sports" />}
                    <BtnRight primary={primary}>
                        <u>Learn More</u>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </BtnRight>
                </ButtonsContainer>
            </InnerContainer>
        </Container>
    )
}

const Products = () => (
    <>
        <Product primary
            paddingLeft={"7vw"}
            imageURL={"images/products/product1.png"}
            desktopImageURL={"/images/products/product1_desktop.png"}
            desktopFooterURL={"/images/products/product1_footer.png"}
            headline={"American sports | Hit 6"}
            description={"Fully customised predictions games for any sport. Predicting the winners and spread."}
        />
        <Product
            paddingLeft={"16vw"}
            imageURL={"images/products/product2.png"}
            desktopImageURL={"/images/products/product2_desktop.png"}
            desktopFooterURL={"/images/products/product23_footer.png"}
            headline={"Tournament brackets"}
            description={"Interactive bracket challenge for any sport, tournament or playoff. Predicting the tournament outcome and winner!"}
        />
        <Product primary
            paddingLeft={"17vw"}
            imageURL={"images/products/product3.png"}
            desktopImageURL={"/images/products/product3_desktop.png"}
            desktopFooterURL={"/images/products/product23_footer.png"}
            headline={"Match Predictor"}
            description={"Free-to-play & pay-to-play prediction games  for both pre-event and in-play engagement."}
        />
    </>
)

export default Products;