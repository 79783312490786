import { useMediaQuery } from "react-responsive";
import { FaqPhoneImg } from "../../../assets/images/bracket";
import * as S from "./Faq.styles";

// Constants
const SECTION_TITLE = "COMMON QUESTIONS";

const CONTENT = [
	{
		title: "HOW DOES IT WORK?",
		content:
			"Start by predicting the final ranking of each group. That will give you the qualifiers for the round of 16. From there predict the winner of each match in the knockout stages all the way to the final and crown your overall winner.",
	},
	{
		title: "HOW DO I SCORE POINTS?",
		content:
			"For the group stage you have to predict the final standings of each group. For each team that finishes in the position you predicted, you will get 3 points. For each match in the knockout stage, you have to predict which team will go through to the next round, starting with the round of 16. You will get 3 points for every time you correctly predict the team that goes through, regardless of their opponent.",
	},
	{
		title: "DO I HAVE TO PAY TO PLAY?",
		content:
			"No, tournament predictor is completely free of charge. However if you want to save and track your score, you will have to sign up or login.",
	},
];

const MobileFaq = () => {
	return (
		<>
			<S.Title> {SECTION_TITLE} </S.Title>
			{CONTENT.map((item, index) => (
				<S.AccordionContainer content={CONTENT} index={index} key={index}>
					<S.Summary
						expandIcon={
							<div style={{ margin: "0 10px 0 5px", color: "#368efa" }}>|</div>
						}
					>
						<S.Question> {item.title}</S.Question>
					</S.Summary>
					<S.Details>
						<S.Answer> {item.content} </S.Answer>
					</S.Details>
				</S.AccordionContainer>
			))}
		</>
	);
};

const DesktopFaq = () => {
	return (
		<S.Container>
			<div
				style={{
					marginTop: "6.25rem",
					flex: 0.5,
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<img src={FaqPhoneImg} alt="" width={450} height={350} />
			</div>
			<div style={{ display: "flex", flexDirection: "column", flex: 0.5 }}>
				<S.Title> {SECTION_TITLE} </S.Title>
				{CONTENT.map((item, index) => (
					<div key={index}>
						<S.ContentTitle>{item.title}</S.ContentTitle>
						<S.ContentText> {item.content} </S.ContentText>
					</div>
				))}
			</div>
		</S.Container>
	);
};

const Faq = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	return isMobile ? <MobileFaq /> : <DesktopFaq />;
};

export default Faq;
