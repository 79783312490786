import styled from "styled-components";
import { ViewContainer } from "../../../components/general-components";
import { SwiperSlide } from "swiper/react";

export const HowToPlayWrapper = styled.div`
	background-color: #368efa;
	height: 95vh;
	@media all and (min-width: 768px) {
		height: auto;
		padding: 2rem 10%;
		overflow-x: hidden;
	}
`;
export const HowToPlayContainer = styled(ViewContainer)`
	display: flex;
	background-color: #368efa;
	gap: 80px;
	justify-content: center;
	width: 80%;
	height: unset;
`;

export const HowToPlayTitle = styled.h3`
	padding-top: 1.8rem;
	margin-bottom: 4rem;
	text-align: center;
	color: #ffffff;
	font-weight: 800;
	font-size: 2.8rem;
	font-family: Sen;
	@media all and (min-width: 768px) {
		padding-top: 0;
		font-size: 2.5rem;
		text-align: left;
	}
`;

export const HowToPlayItem = styled.div`
	width: 100%;
	display: flex;
	gap: 15px;
	flex-direction: column;
`;

export const HowToPlaySliderImg = styled.img`
	width: 90%;
	margin-left: 3rem;
`;

export const HowToPlayContentTitle = styled.h5`
	color: #ffffff;
	font-size: 1.5rem;
	width: 70%;
	text-align: center;
	margin: 1.5rem 1rem 1rem 1rem;
	font-family: Sen;
	font-weight: 600;
	@media all and (min-width: 768px) {
		width: 100%;
		margin: 1rem 0;
		text-align: left;
		font-weight: 100;
	}
`;
export const HowToPlayContentPara = styled.p`
	margin-bottom: 0;
	width: 90%;
	color: #ffffff;
	text-align: center;
	font-size: 1.2rem;
	@media all and (min-width: 768px) {
		margin-bottom: 1rem;
		width: 80%;
		font-size: 0.8rem;
		text-align: left;
		align-self: flex-start;
	}
`;

export const SliderContainer = styled.div`
	padding: 0 24px 0 49px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const SliderItem = styled(SwiperSlide)`
	text-align: center;
`;

export const SubSection = styled.div`
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
`;
