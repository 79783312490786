import "./App.css";
import NavMenu from "./components/nav-menu";
import BracketNav from "./components/bracket-nav";
import { useState } from "react";
import styled from "styled-components";
import MainSite from "./views/MainSite";
import { useMediaQuery } from "react-responsive";
import NavHeaderMenu from "./components/nav-header-menu";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Bracket from "./views/Bracket/Bracket";

const Header = styled.header`
	height: var(--header-height);
	background-color: var(--panel);
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 1.3rem;
`;

const LogoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 2.4rem;
	font-size: 0.7rem;
	color: var(--primary);
	font-family: Aharoni;
	@media (min-width: 769px) {
		flex-direction: row;
		height: 100%;
		width: 9rem;
		font-size: 1.2rem;
	}
`;

function App() {
	const [isNavOpen, setIsNavOpen] = useState(false);
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const location = useLocation();
	return (
		<>
			{isMobile && location.pathname.includes("bracket") ? (
				<BracketNav isOpen={isNavOpen} onClose={() => setIsNavOpen(false)} />
			) : (
				<NavMenu isOpen={isNavOpen} onClose={() => setIsNavOpen(false)} />
			)}
			<Header>
				{isMobile && <div className={"flex-1"} />}
				<LogoContainer>
					<img
						src={"/images/logo-small.svg"}
						style={{ height: "1.5rem" }}
						alt="logo"
					/>
					<div>Hook Apps</div>
				</LogoContainer>
				<div className={"right flex-1"} style={{}}>
					{isMobile ? (
						<>
							<label for="navToggle">
								<img src={"/images/burger-menu.png"} alt="menu icon" />
							</label>
							<input
								type="checkbox"
								checked={isNavOpen}
								id="navToggle"
								hidden
								onChange={() => {
									setIsNavOpen(!isNavOpen);
								}}
							/>
						</>
					) : (
						<NavHeaderMenu />
					)}
				</div>
			</Header>
			<Routes>
				<Route path="/" element={<MainSite />} />
				<Route path="/bracket" element={<Bracket />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</>
	);
}

export default App;
