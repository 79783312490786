import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Link } from 'react-scroll';

export const ViewContainer = styled.div`
	width: 100%;
	height: calc((100 * var(--vh)) - var(--header-height));
	min-height: 30rem;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	&:first-child {
		margin-bottom: 1px;
	}
`;


const NextPageStyled = styled.a`
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 1.5rem;
    color: var(--background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 3.5rem;
    font-size: 1.1rem;
    cursor: pointer;
`

export const NextPageLink = ({ children, ...props }) => <NextPageStyled onClick={e => {
    e.preventDefault(); 
    document.querySelector(`#${props.to}`).scrollIntoView({ behavior: 'smooth' });

}} {...props}>
    {children}
    <FontAwesomeIcon icon={faAngleDoubleDown} />
</NextPageStyled>

export const shortScreenQuery = `@media (max-height: 735px)`
export const desktopScreenQuery = `@media (min-width: 769px)`