import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { ContactManImg } from "../../../assets/images/bracket";
import axiosInstance from "../../../config/axios";
import * as S from "./Contact.styles";

const Contact = () => {
	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
	} = useForm();
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

	const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

	const onSubmit = (data) =>
		axiosInstance.post("/landing-page/contact-message", data).then(() => {
			setIsSuccessfullySubmitted(true);
		});
	return (
		<>
			{isMobile && (
				<div>
					<img
						src={ContactManImg}
						alt=""
						width={400}
						height={400}
						style={{ marginBottom: "-10px" }}
					/>
				</div>
			)}

			<S.Container isMobile={isMobile}>
				<div>
					<S.Title>
						READY TO TALK ABOUT <br />
						<span style={{ fontSize: "1.5rem" }}>YOUR</span> TURNAMENT
						PREDICTOR?
					</S.Title>
					{isSuccessfullySubmitted ? (
						<>
							<S.SentImg src="/images/contacts/successful.png" />
							<S.SuccessHeadline>
								Thank you for contacting us!
							</S.SuccessHeadline>
							<p>We have received your message. We'll be in touch shortly!</p>
							<S.BackBtn to="home" containerId="main">
								Back to the home page
							</S.BackBtn>
						</>
					) : (
						<form
							id="contactForm"
							onSubmit={handleSubmit(onSubmit)}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<S.TextInput
								id="name"
								required
								{...register("name", { required: true })}
								disabled={isSubmitting}
								type="text"
								placeholder="Your name"
							/>
							<S.TextInput
								id="company"
								required
								{...register("company", { required: true })}
								disabled={isSubmitting}
								type="text"
								placeholder="Your company name"
							/>
							<S.TextInput
								id="email"
								required
								{...register("email", {
									required: true,
								})}
								type="email"
								disabled={isSubmitting}
								placeholder="Email address"
							/>
							<S.TextInput
								id="phone"
								required
								{...register("phone", {
									required: true,
								})}
								type="phone"
								disabled={isSubmitting}
								placeholder="Phone number"
							/>
							<S.SendBtn
								type="submit"
								form="contactForm"
								disabled={isSubmitting}
							>
								<span>GET YOUR GAME ON</span>
								{/* <img
							style={{ width: "1rem" }}
							src="/images/contacts/send_now.svg"
							alt="send_now"
						/> */}
							</S.SendBtn>
						</form>
					)}
				</div>
				{!isMobile && (
					<div style={{ position: "absolute", right: 0, bottom: "-4px" }}>
						<img src={ContactManImg} alt="" width={400} height={400} />
					</div>
				)}
			</S.Container>
		</>
	);
};

export default Contact;
