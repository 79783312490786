import { Link } from "react-router-dom";
import styled from "styled-components";
import { ViewContainer } from "../../../components/general-components";

export const Container = styled(ViewContainer)`
	position: relative;
	padding: ${({ isMobile }) => (isMobile ? "0 10%" : "0 20%")};
	height: 80%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: #368efa;
`;

export const Title = styled.h2`
	font-weight: bold;
	font-size: 1.3rem;
`;

export const TextInput = styled.input`
	&::placeholder {
		color: black;
		text-align: left;
	}
	color: black !important;
	height: 3rem;
	border: none;
	color: #000;
	border-bottom: 1px solid black;
	background-color: transparent;
	margin-bottom: 1rem;
	font-size: 1.1rem;
	font-family: Sen;
`;

export const SendBtn = styled.button`
	width: 15rem;
	background-color: #54ffaa;
	height: 3rem;
	border: 1px solid #38094b;
	border-radius: 30px;
	font-family: Sen;
	color: #38094b;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	font-weight: bold;
	cursor: pointer;
`;

export const SuccessHeadline = styled.h3`
	font-weight: 600;
	font-size: 1.4rem;
`;

export const BackBtn = styled(Link)`
	width: 14.4rem;
	max-width: 100%;
	margin: 0 auto;
	background-color: var(--secondary);
	height: 3rem;
	border: none;
	border-radius: 7px;
	font-family: Sen;
	color: var(--background);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const SentImg = styled.img`
	margin: -5.3rem 0 0 0;
`;
