import styled from "styled-components";
import { ViewContainer } from "../components/general-components";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-scroll";
import axiosInstance from "../config/axios";

const Container = styled(ViewContainer)`
	--black: #4040408a;
	background-image: linear-gradient(0deg, var(--black), var(--black)),
		url(/images/contactsBG.png);
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc((100 * var(--vh)) - var(--header-height) - var(--footer-height));
`;

const FormContainer = styled.div`
	border-radius: 20px;
	background-color: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(15px);
	text-align: center;
	padding: 2.6rem 1.4rem;
	color: var(--background);
	max-width: 20.7rem;
`;

const H3 = styled.h3`
	font-family: Aharoni;
	margin: 0 0 1.6rem 0;
	font-size: 1.9rem;
`;

const TextInput = styled.input`
	width: 100%;
	height: 3rem;
	border: none;
	border-radius: 7px;
	background-color: #eaeaea;
	margin-bottom: 1rem;
	padding: 0 1rem;
	font-family: Sen;
`;

const Textarea = styled.textarea`
	width: 100%;
	height: 8.2rem;
	border: none;
	border-radius: 7px;
	background-color: #eaeaea;
	margin-bottom: 1rem;
	padding: 1rem;
	font-family: Sen;
`;

const SendBtn = styled.button`
	width: 100%;
	background-color: var(--primary);
	height: 3rem;
	border: none;
	border-radius: 7px;
	font-family: Sen;
	color: var(--background);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const SuccessHeadline = styled.h3`
	font-weight: 600;
	font-size: 1.4rem;
`;

const BackBtn = styled(Link)`
	width: 14.4rem;
	max-width: 100%;
	margin: 0 auto;
	background-color: var(--secondary);
	height: 3rem;
	border: none;
	border-radius: 7px;
	font-family: Sen;
	color: var(--background);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const SentImg = styled.img`
	margin: -5.3rem 0 0 0;
`;

const Contacts = () => {
	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
	} = useForm();
	const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

	const onSubmit = (data) =>
		axiosInstance.post("/landing-page/contact-message", data).then(() => {
			setIsSuccessfullySubmitted(true);
		});

	return (
		<Container>
			<FormContainer>
				{isSuccessfullySubmitted ? (
					<>
						<SentImg src="/images/contacts/successful.png" />
						<SuccessHeadline>Thank you for contacting us!</SuccessHeadline>
						<p>We have received your message. We'll be in touch shortly!</p>
						<BackBtn to="home" containerId="main">
							Back to the home page
						</BackBtn>
					</>
				) : (
					<form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
						<H3>Contact us</H3>
						<TextInput
							id="name"
							required
							{...register("name", { required: true })}
							disabled={isSubmitting}
							type="text"
							placeholder="Your name"
						/>
						<TextInput
							id="email"
							required
							{...register("email", {
								required: true,
							})}
							type="email"
							disabled={isSubmitting}
							placeholder="Email address"
						/>
						<Textarea
							required
							{...register("message", { required: true })}
							disabled={isSubmitting}
							placeholder="Message"
						/>
						<SendBtn type="submit" form="contactForm" disabled={isSubmitting}>
							<span>Send now&nbsp;</span>
							<img
								style={{ width: "1rem" }}
								src="/images/contacts/send_now.svg"
								alt="send_now"
							/>
						</SendBtn>
					</form>
				)}
			</FormContainer>
		</Container>
	);
};

export default Contacts;
