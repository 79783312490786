import { Link, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink, scroller } from "react-scroll";
import styled from "styled-components";

const Nav = styled.nav`
	font-size: 0.9rem;
`;

const Ul = styled.ul`
	display: flex;
	flex-direction: row;
`;

const NavItem = styled.li`
	display: block;
	text-align: center;
	width: 8rem;
	color: #000e3f;
	cursor: pointer;
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	color:black;
	&:focus,
	&:hover,
	&:visited
	&:link,
	&:active {
		text-decoration: none;
		color:black
	}
`;

const NavHeaderMenu = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const NavLink = ({ ...props }) => {
		const customScrollTo = (e) => {
			// e.preventDefault();
			e.preventDefault();
			navigate("/");
			setTimeout(() => {
				document
					.querySelector(`#${props.to}`)
					.scrollIntoView({ behavior: "smooth" });
				// 	console.log("scrolled");
				// 	scroller.scrollTo("products", {
				// 		duration: 1500,
				// 		delay: 100,
				// 		smooth: true,
				// 		offset: 50,
				// 	});
			}, 300);
		};
		return pathname !== "/" ? (
			<ScrollLink onClick={customScrollTo}>{props.children}</ScrollLink>
		) : (
			<ScrollLink
				// spy={true}
				// smooth={true}
				// offset={50}
				// duration={1500}
				onClick={() =>
					document
						.querySelector(`#${props.to}`)
						.scrollIntoView({ behavior: "smooth" })
				}
				activeClass="header-link_active"
				// containerId="main"
				// {...props}
			>
				{props.children}
			</ScrollLink>
		);
	};

	return (
		<>
			<Nav>
				<Ul>
					<NavItem>
						<NavLink to="home">HOME</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="about">ABOUT</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="products">PRODUCTS</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="contacts">CONTACTS</NavLink>
					</NavItem>
					<NavItem>
						<StyledLink to="bracket">BRACKET</StyledLink>
					</NavItem>
				</Ul>
			</Nav>
		</>
	);
};

export default NavHeaderMenu;
