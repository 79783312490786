import styled from "styled-components";
import { ViewContainer } from "../../../components/general-components";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

export const Container = styled(ViewContainer)`
	margin-top: 5rem;
	display: flex;
	background-color: #fff;
	padding: 0 10%;
	justify-content: center;
	height: auto;
`;

export const Title = styled.h3`
	font-size: 2.5rem;
	padding-left: 1rem;
	@media all and (min-width: 768px) {
		padding-left: 0;
		text-align: left;
		font-weight: 800;
		color: #09274b;
		font-size: 1.7rem;
		margin-bottom: 2.5rem;
	}
`;

export const ContentTitle = styled.h5`
	margin: 0;
	margin-top: 1.2rem;
	color: #368efa;
	font-weight: bold;
	@media all and (min-width: 768px) {
		font-size: 1.1rem;
	}
`;

export const ContentText = styled.p`
	color: #09274b;
	font-size: 0.8rem;
`;

export const AccordionContainer = styled(Accordion)`
	position: unset !important;
	border-bottom: 1px solid #368efa;
	border-top: ${({ index, content }) =>
		index === 0 ? "1px solid #368EFA" : "none"};
	box-shadow: none !important;
	margin-bottom: 1rem;
`;
export const Summary = styled(AccordionSummary)`
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
`;
export const Question = styled.div`
	color: #368efa;
	font-size: 1.2rem;
	font-family: "Sen";
`;
export const Answer = styled.div`
	color: black;
	font-size: 1.1rem;
	padding: 0 16px;
	font-family: "Sen";
`;
export const Details = styled(AccordionDetails)`
	color: #368efa;
	font-size: 1rem;
`;
