import { useMediaQuery } from "react-responsive";
import {
	FeaturesBackground,
	FeaturesBannerImage,
	FeaturesMobileBackground,
	FeaturesMobileImage,
} from "../../../assets/images/bracket";
import * as S from "./Features.styles";

// Constants
const SECTION_TITLE = "FEATURES";
const CONTENT_ITEMS = [
	{
		title: "REPORTS & PRIZES",
		text: "Monitor players progress in real time and reward them with cash prizes, free bets or merchandise. We give you the back office tools you need to set up and manage your prizes.",
	},
	{
		title: "MOBILE FRIENDLY",
		text: "Users can play the 2022 Football Predictor wherever they are,whenever they like. we have made it compatible with all devices, from desktop PCs to tablets and smartphones. It can be embedded to the client or a stand-alone app.",
	},
	{
		title: "CUSTOM BRANDED",
		text: "This is your 2022 Football Predictor, so you can set it up your own way with your company’s unique images and banners, logos and links, events and odds to make it look unique, bespoke and professional.",
	},
];
const FEATURES_ITEMS = [
	{
		text: "New visitors are asked to register and create an account before they can submit their prediction.",
	},
	{
		text: "We offer smart bets to players both pre match and in-play.",
	},
	{
		text: "Free bonuses fo single correct predictions.",
	},
];

const FeatureItem = ({ index, text }) => {
	return (
		<S.FeatureItem>
			<S.FeatureItemIndex>{index}</S.FeatureItemIndex>
			<S.FeatureItemText>{text}</S.FeatureItemText>
		</S.FeatureItem>
	);
};

const Features = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	return (
		<S.Wrapper>
			<S.Container
				style={{
					backgroundImage: isMobile
						? `url(${FeaturesMobileBackground})`
						: `url(${FeaturesBackground})`,
				}}
			>
				<S.Title> {SECTION_TITLE} </S.Title>
				<S.ContentContainer>
					{CONTENT_ITEMS.map((item, index) => (
						<S.ContentItem key={index}>
							<S.ContentTitle> {item.title} </S.ContentTitle>
							<S.ContentText> {item.text} </S.ContentText>
						</S.ContentItem>
					))}
				</S.ContentContainer>
			</S.Container>
			{isMobile && (
				<S.BannerContainer>
					<S.BannerTitle>
						CONVERTING USERS FROM FTP PLAYERS TO REAL MONEY BETTORS
					</S.BannerTitle>
					<div>
						<img src={FeaturesMobileImage} alt="" width={"100%"} />
					</div>
					<S.BannerSubTitle>
						We offer 3 touchpoint for conversion:
					</S.BannerSubTitle>
					{FEATURES_ITEMS.map((item, index) => (
						<FeatureItem text={item.text} index={index + 1} key={index} />
					))}
				</S.BannerContainer>
			)}

			{!isMobile && (
				<S.BannerContainer>
					<S.BannerContent>
						<S.FeaturesContent>
							<S.BannerTitle>
								CONVERTING USERS FROM FTP <br /> PLAYERS TO REAL MONEY BETTORS
							</S.BannerTitle>
							<S.BannerSubTitle>
								We offer three touchpoint for conversion:
							</S.BannerSubTitle>
							<S.FeaturesContentList>
								{FEATURES_ITEMS.map((item, index) => (
									<FeatureItem text={item.text} index={index + 1} key={index} />
								))}
							</S.FeaturesContentList>
						</S.FeaturesContent>
						<S.ImageBox>
							<img src={FeaturesBannerImage} alt="" width={500} />
						</S.ImageBox>
					</S.BannerContent>
				</S.BannerContainer>
			)}
		</S.Wrapper>
	);
};

export default Features;
